import React, { useEffect, ReactElement } from 'react';
import { useRouter } from 'next/router';
import { ErrorPage } from '@/features';
import { Button } from '@/components';

export default function NotFound(): ReactElement {
  const router = useRouter();
  const { asPath } = useRouter();

  useEffect(() => {
    window.newrelic?.noticeError(new Error('Page not found'), {
      extra: {
        path: asPath,
      },
    });
  }, [asPath]);

  return (
    <ErrorPage
      title="This page does not exist"
      description="The link you followed may be broken, or the page may have been removed."
    >
      <Button variant="text-blue" onPress={() => router.back()}>
        Go back to previous page
      </Button>
    </ErrorPage>
  );
}
